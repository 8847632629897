import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "manage-abo-container" }
const _hoisted_2 = {
  key: 0,
  class: "subLabel"
}
const _hoisted_3 = {
  key: 1,
  class: "subLabel"
}
const _hoisted_4 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "abo-list" }
const _hoisted_9 = {
  key: 0,
  class: "special-abo-label"
}
const _hoisted_10 = { class: "text" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "abo-price" }
const _hoisted_15 = {
  key: 7,
  class: "abo-hint"
}
const _hoisted_16 = {
  key: 8,
  class: "special-abo-description"
}
const _hoisted_17 = { class: "price-hint" }
const _hoisted_18 = {
  key: 0,
  class: "change-abo"
}
const _hoisted_19 = { class: "info-item" }
const _hoisted_20 = { class: "info-item-label" }
const _hoisted_21 = { class: "text" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "info-item-action" }
const _hoisted_24 = {
  key: 1,
  class: "cancel-abo"
}
const _hoisted_25 = {
  key: 1,
  class: "info-item"
}
const _hoisted_26 = { class: "info-item-label" }
const _hoisted_27 = { class: "text" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "info-item-action" }
const _hoisted_30 = { class: "manage-abo-selected" }
const _hoisted_31 = { class: "selected-abo-header" }
const _hoisted_32 = { class: "selected-abo" }
const _hoisted_33 = {
  class: "manage-abo-payment",
  ref: "domAboPayment"
}
const _hoisted_34 = { class: "billing-header" }
const _hoisted_35 = { class: "ar-field" }
const _hoisted_36 = { class: "formlabel" }
const _hoisted_37 = ["placeholder"]
const _hoisted_38 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_39 = { class: "ar-field" }
const _hoisted_40 = { class: "formlabel" }
const _hoisted_41 = ["placeholder"]
const _hoisted_42 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_43 = { class: "ar-field" }
const _hoisted_44 = { class: "formlabel" }
const _hoisted_45 = ["placeholder"]
const _hoisted_46 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_47 = { class: "ar-field" }
const _hoisted_48 = { class: "formlabel" }
const _hoisted_49 = ["placeholder"]
const _hoisted_50 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_51 = { class: "ar-field" }
const _hoisted_52 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_53 = { class: "ar-field checkbox" }
const _hoisted_54 = { for: "isCompany" }
const _hoisted_55 = {
  key: 0,
  class: "ar-field"
}
const _hoisted_56 = { class: "formlabel" }
const _hoisted_57 = ["placeholder"]
const _hoisted_58 = {
  key: 1,
  class: "info-item"
}
const _hoisted_59 = { class: "info-item-label" }
const _hoisted_60 = { class: "text" }
const _hoisted_61 = ["innerHTML"]
const _hoisted_62 = {
  key: 2,
  class: "ar-field"
}
const _hoisted_63 = { class: "formlabel" }
const _hoisted_64 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_65 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_66 = {
  class: "manage-abo-payment",
  ref: "domAboPayment"
}
const _hoisted_67 = { key: 0 }
const _hoisted_68 = {
  href: "https://www.alprelax.at/#preise",
  target: "_blank"
}
const _hoisted_69 = { class: "payment-method" }
const _hoisted_70 = {
  key: 0,
  class: "payment-data payment-data--needed"
}
const _hoisted_71 = { key: 0 }
const _hoisted_72 = { key: 1 }
const _hoisted_73 = {
  key: 2,
  class: "container-payments",
  ref: "domPaymentPrepared"
}
const _hoisted_74 = ["action", "data-brands"]
const _hoisted_75 = {
  key: 1,
  class: "payment-data payment-data--no-needed"
}
const _hoisted_76 = { class: "info-item" }
const _hoisted_77 = { class: "info-item-label" }
const _hoisted_78 = { class: "text" }
const _hoisted_79 = ["innerHTML"]
const _hoisted_80 = {
  key: 0,
  class: "form-section"
}
const _hoisted_81 = { class: "ar-field checkbox" }
const _hoisted_82 = {
  key: 0,
  class: "ar-field"
}
const _hoisted_83 = { class: "formlabel" }
const _hoisted_84 = ["placeholder"]
const _hoisted_85 = { class: "form-section" }
const _hoisted_86 = { class: "ar-field checkbox" }
const _hoisted_87 = {
  key: 0,
  class: "ar-field"
}
const _hoisted_88 = { class: "formlabel" }
const _hoisted_89 = ["placeholder"]
const _hoisted_90 = {
  key: 1,
  class: "finished"
}
const _hoisted_91 = { key: 0 }
const _hoisted_92 = { key: 1 }
const _hoisted_93 = { key: 2 }
const _hoisted_94 = { key: 3 }
const _hoisted_95 = { key: 4 }
const _hoisted_96 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Select = _resolveComponent("Select")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, { defaultHeader: true }, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('header.manageAbo')), 1),
        (!_ctx.aboChanged && !_ctx.showLoader)
          ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t('header.selectAbo')), 1))
          : _createCommentVNode("", true),
        (_ctx.showLoader)
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.$t('header.loading')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["manage-abo-content", {'no-fixed-header': _ctx.aboChanged }])
    }, [
      (_ctx.showLoader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("i", {
              class: "svg-icon",
              innerHTML: _ctx.icons.loader
            }, null, 8, _hoisted_5)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (!_ctx.aboChanged)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.abos, (abo) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: abo.uid
                      }, [
                        ((abo.price > 0) || (abo.price == 0 && _ctx.user.abo.uid == abo.uid))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(abo.isSpecialOffer? 'abo-special-list-item' :'abo-list-item')
                            }, [
                              (abo.isSpecialOffer)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(abo.title), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (abo.isSpecialOffer && abo.price > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(abo.paymentInterVal), 1))
                                : (abo.isSpecialOffer && abo.price == 0)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(abo.termTime), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(abo.title), 1)),
                              _createElementVNode("span", _hoisted_14, "€ " + _toDisplayString(_ctx.formatPrice(abo.price)), 1),
                              ((_ctx.user.abo.uid==abo.uid))
                                ? (_openBlock(), _createBlock(_component_Button, {
                                    key: 4,
                                    text: _ctx.$t('button.activ'),
                                    inline: true,
                                    disabled: true
                                  }, null, 8, ["text"]))
                                : ((_ctx.user.abo.aboSuccessor==abo.uid))
                                  ? (_openBlock(), _createBlock(_component_Button, {
                                      key: 5,
                                      text: _ctx.$t('button.aboSuccessor'),
                                      inline: true,
                                      disabled: true
                                    }, null, 8, ["text"]))
                                  : (_openBlock(), _createBlock(_component_Button, {
                                      key: 6,
                                      text: _ctx.selectedAbo == abo.uid ? _ctx.$t('button.selected') : _ctx.$t('button.select'),
                                      inline: true,
                                      onHandleClicked: ($event: any) => (_ctx.handleSelectAbo(abo))
                                    }, null, 8, ["text", "onHandleClicked"])),
                              (abo.priceHint)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(abo.priceHint), 1))
                                : _createCommentVNode("", true),
                              (abo.isSpecialOffer && abo.specialOfferDescription)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(abo.specialOfferDescription), 1))
                                : _createCommentVNode("", true)
                            ], 2))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('abo.pricesIncludeTax')), 1),
                    (_ctx.user.abo?.aboSuccessor)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("p", _hoisted_20, [
                              _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('abo.changeAbo')), 1)
                            ]),
                            _createElementVNode("p", {
                              class: "cancel-abo-message info-item-message",
                              innerHTML: _ctx.$t('abo.changeAboMessage', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate)})
                            }, null, 8, _hoisted_22),
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(_component_Button, {
                                text: _ctx.$t('button.redoChangeMembership'),
                                inline: true,
                                disabled: true,
                                dense: true,
                                onHandleClicked: _ctx.handleRedoChangeAbo
                              }, null, 8, ["text", "onHandleClicked"])
                            ])
                          ]),
                          _createVNode(_component_Dialog, {
                            show: _ctx.showConfirmCancelDialog,
                            title: _ctx.$t('dialog.areYourSure'),
                            text: (_ctx.$t('dialog.dear', {name: _ctx.user.firstName}) + '<br><br>' + _ctx.$t('dialog.confirmCancelAbo', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate)})),
                            buttonText: _ctx.$t('button.cancelMembership'),
                            onHandleConfirm: _ctx.cancelAbo,
                            onHandleCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmCancelDialog = false))
                          }, null, 8, ["show", "title", "text", "buttonText", "onHandleConfirm"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.currentUserAbo.price > 0 && !_ctx.selectedAbo)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          (!_ctx.user.abo.cancelByDate)
                            ? (_openBlock(), _createBlock(_component_Button, {
                                key: 0,
                                text: _ctx.$t('button.cancelMembership'),
                                inline: true,
                                disabled: true,
                                dense: true,
                                onHandleClicked: _ctx.handleCancelAbo
                              }, null, 8, ["text", "onHandleClicked"]))
                            : _createCommentVNode("", true),
                          (_ctx.user.abo.cancelByDate && !_ctx.user.abo.aboSuccessor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                _createElementVNode("p", _hoisted_26, [
                                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('abo.canceledHeadline')), 1)
                                ]),
                                _createElementVNode("p", {
                                  class: "cancel-abo-message info-item-message",
                                  innerHTML: _ctx.$t('abo.canceledMessage', {cancelByDate: _ctx.formatDate(_ctx.user.abo.cancelByDate)})
                                }, null, 8, _hoisted_28),
                                _createElementVNode("div", _hoisted_29, [
                                  (_ctx.user.abo.cancelByDate)
                                    ? (_openBlock(), _createBlock(_component_Button, {
                                        key: 0,
                                        text: _ctx.$t('button.redoCancelMembership'),
                                        inline: true,
                                        disabled: true,
                                        dense: true,
                                        onHandleClicked: _ctx.handleRedoCancelAbo
                                      }, null, 8, ["text", "onHandleClicked"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_Dialog, {
                            show: _ctx.showConfirmCancelDialog,
                            title: _ctx.$t('dialog.areYourSure'),
                            text: (_ctx.$t('dialog.dear', {name: _ctx.user.firstName}) + '<br><br>' + _ctx.$t('dialog.confirmCancelAbo', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate)}) + '<br><br>' + _ctx.$t('dialog.confirmCancelAboQuestion')),
                            buttonText: _ctx.$t('button.cancelMembership'),
                            onHandleConfirm: _ctx.cancelAbo,
                            onHandleCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showConfirmCancelDialog = false))
                          }, null, 8, ["show", "title", "text", "buttonText", "onHandleConfirm"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 512), [
                    [_vShow, !_ctx.selectedAbo]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_30, [
                    _createElementVNode("h3", _hoisted_31, _toDisplayString(_ctx.$t('abo.selectedAbo')), 1),
                    _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.getAboDetails()), 1)
                  ], 512), [
                    [_vShow, _ctx.selectedAbo]
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _withDirectives(_createElementVNode("form", {
                      class: "form form--billing",
                      onSubmit: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleSubmitBillingForm && _ctx.handleSubmitBillingForm(...args)))
                    }, [
                      _createElementVNode("h3", _hoisted_34, _toDisplayString(_ctx.$t('billing.header')), 1),
                      _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('formBilling.street')), 1),
                        _withDirectives(_createElementVNode("input", {
                          name: "street",
                          type: "text",
                          placeholder: _ctx.$t('formBilling.street')+ '*',
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formBilling.street) = $event))
                        }, null, 8, _hoisted_37), [
                          [_vModelText, _ctx.formBilling.street]
                        ]),
                        (_ctx.getErrorClass('string', _ctx.formBilling.street)=='required')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.$t('error.required')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('formBilling.housenumber')), 1),
                        _withDirectives(_createElementVNode("input", {
                          name: "housenumber",
                          type: "text",
                          placeholder: _ctx.$t('formBilling.housenumber')+ '*',
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formBilling.housenumber) = $event))
                        }, null, 8, _hoisted_41), [
                          [_vModelText, _ctx.formBilling.housenumber]
                        ]),
                        (_ctx.getErrorClass('string', _ctx.formBilling.housenumber)=='required')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_42, _toDisplayString(_ctx.$t('error.required')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t('formBilling.zip')), 1),
                        _withDirectives(_createElementVNode("input", {
                          name: "zip",
                          type: "text",
                          placeholder: _ctx.$t('formBilling.zip')+ '*',
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formBilling.zip) = $event))
                        }, null, 8, _hoisted_45), [
                          [_vModelText, _ctx.formBilling.zip]
                        ]),
                        (_ctx.getErrorClass('string', _ctx.formBilling.housenumber)=='required')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString(_ctx.$t('error.required')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('formBilling.city')), 1),
                        _withDirectives(_createElementVNode("input", {
                          name: "city",
                          type: "text",
                          placeholder: _ctx.$t('formBilling.city')+ '*',
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formBilling.city) = $event))
                        }, null, 8, _hoisted_49), [
                          [_vModelText, _ctx.formBilling.city]
                        ]),
                        (_ctx.getErrorClass('string', _ctx.formBilling.city)=='required')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_50, _toDisplayString(_ctx.$t('error.required')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_51, [
                        _createVNode(_component_Select, {
                          label: _ctx.$t('formBilling.country'),
                          value: _ctx.formBilling.country,
                          name: 'country',
                          options: _ctx.countries,
                          optionValue: 'uid',
                          optionText: 'name',
                          onChange: _ctx.onChangeCountry
                        }, null, 8, ["label", "value", "options", "onChange"]),
                        (_ctx.getErrorClass('string', _ctx.formBilling.country)=='required')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_52, _toDisplayString(_ctx.$t('error.required')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_53, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          id: "isCompany",
                          name: "isCompany",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isCompany) = $event)),
                          onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onIsCompanyChange && _ctx.onIsCompanyChange(...args)))
                        }, null, 544), [
                          [_vModelCheckbox, _ctx.isCompany]
                        ]),
                        _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t('formBilling.iAmaCompany')), 1)
                      ]),
                      (_ctx.isCompany)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                            _createElementVNode("label", _hoisted_56, _toDisplayString(_ctx.$t('formBilling.taxIdentificationNumber')), 1),
                            _withDirectives(_createElementVNode("input", {
                              name: "taxIdentificationNumber",
                              type: "text",
                              placeholder: _ctx.$t('formBilling.taxIdentificationNumber')+ '*',
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formBilling.taxIdentificationNumber) = $event))
                            }, null, 8, _hoisted_57), [
                              [_vModelText, _ctx.formBilling.taxIdentificationNumber]
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.getExistingPaymentData() && _ctx.currentUserAbo.price != 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                            _createElementVNode("p", _hoisted_59, [
                              _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.$t('abo.paymentInfo')), 1)
                            ]),
                            _createElementVNode("p", {
                              class: "no-payment-data-needed-abo-message info-item-message",
                              innerHTML: _ctx.$t('abo.paymentOnChanging', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate)})
                            }, null, 8, _hoisted_61)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.currentUserAbo.price != 0  && _ctx.getExistingPaymentData())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                            _createElementVNode("label", _hoisted_63, _toDisplayString(_ctx.$t('formPassword.password')), 1),
                            _createVNode(_component_PasswordInput, {
                              placeholder: _ctx.$t('formPassword.password'),
                              onOnChange: _cache[9] || (_cache[9] = (value) => _ctx.handleChangePasswordValue(value))
                            }, null, 8, ["placeholder"]),
                            (_ctx.getErrorClass('password', _ctx.formPassword.password)=='required')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_64, _toDisplayString(_ctx.$t('error.required')), 1))
                              : _createCommentVNode("", true),
                            (_ctx.getErrorClass('password', _ctx.formPassword.password)=='invalid')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_65, _toDisplayString(_ctx.$t('error.serverInvalidPassword')), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.getAboPrice() != 0 && !_ctx.paymentPrepared)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 3,
                            text: ((_ctx.currentUserAbo.price != 0  && _ctx.getExistingPaymentData()) ? _ctx.$t('button.confirm') : _ctx.$t('button.payNow')),
                            waitForResponse: _ctx.waitForResponse,
                            onHandleClicked: _ctx.handleSubmitBillingForm
                          }, null, 8, ["text", "waitForResponse", "onHandleClicked"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Button, {
                        class: "btn-cancel",
                        text: _ctx.$t('button.cancel'),
                        dense: true,
                        onHandleClicked: _ctx.handleCancel
                      }, null, 8, ["text", "onHandleClicked"])
                    ], 544), [
                      [_vShow, _ctx.selectedAbo > 0 && !_ctx.billingInfoConfirmed]
                    ])
                  ], 512),
                  _createElementVNode("div", _hoisted_66, [
                    false
                      ? (_openBlock(), _createElementBlock("p", _hoisted_67, [
                          _createElementVNode("a", _hoisted_68, _toDisplayString(_ctx.$t('button.moreInfos')) + ".", 1)
                        ]))
                      : _createCommentVNode("", true),
                    _withDirectives(_createElementVNode("div", _hoisted_69, [
                      (!_ctx.getExistingPaymentData() || _ctx.currentUserAbo.price == 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                            (_ctx.paymentPrepared)
                              ? (_openBlock(), _createElementBlock("h3", _hoisted_71, _toDisplayString(_ctx.$t('button.payNow')), 1))
                              : _createCommentVNode("", true),
                            (_ctx.paymentPrepared)
                              ? (_openBlock(), _createElementBlock("h4", _hoisted_72, _toDisplayString(_ctx.$t('abo.selectPaymentMethode')), 1))
                              : _createCommentVNode("", true),
                            (_ctx.paymentPrepared)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                                  _createElementVNode("form", {
                                    action: _ctx.paymentShopperResultUrl,
                                    class: "paymentWidgets",
                                    "data-brands": _ctx.paymentBrands
                                  }, null, 8, _hoisted_74)
                                ], 512))
                              : _createCommentVNode("", true),
                            (_ctx.getAboPrice() != 0 && _ctx.user.billing && !_ctx.paymentPrepared)
                              ? (_openBlock(), _createBlock(_component_Button, {
                                  key: 3,
                                  text: _ctx.$t('button.payNow'),
                                  waitForResponse: _ctx.waitForResponse,
                                  onHandleClicked: _ctx.handlePreparePayment
                                }, null, 8, ["text", "waitForResponse", "onHandleClicked"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_Dialog, {
                              show: _ctx.handleShowConfirmChangeToFreeAbo,
                              title: _ctx.$t('dialog.areYourSure'),
                              text: (_ctx.$t('dialog.dear', {name: _ctx.user.firstName})+ _ctx.$t('dialog.confirmManagFreeAboText')),
                              buttonText: _ctx.$t('button.unsubscribeAbo'),
                              onHandleConfirm: _ctx.manageAbo,
                              onHandleCancel: _ctx.handleCloseDialog
                            }, null, 8, ["show", "title", "text", "buttonText", "onHandleConfirm", "onHandleCancel"]),
                            _createVNode(_component_Button, {
                              class: "btn-cancel",
                              text: _ctx.$t('button.cancel'),
                              dense: true,
                              onHandleClicked: _ctx.handleCancel
                            }, null, 8, ["text", "onHandleClicked"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.getExistingPaymentData() && _ctx.currentUserAbo.price != 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                            _createElementVNode("div", _hoisted_76, [
                              _createElementVNode("p", _hoisted_77, [
                                _createElementVNode("span", _hoisted_78, _toDisplayString(_ctx.$t('abo.paymentInfo')), 1)
                              ]),
                              _createElementVNode("p", {
                                class: "no-payment-data-needed-abo-message info-item-message",
                                innerHTML: _ctx.$t('abo.paymentOnChanging', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate)})
                              }, null, 8, _hoisted_79)
                            ]),
                            _createVNode(_component_Button, {
                              text: _ctx.$t('button.confirm'),
                              waitForResponse: _ctx.waitForResponse,
                              onHandleClicked: _ctx.handleChangeAbo
                            }, null, 8, ["text", "waitForResponse", "onHandleClicked"]),
                            _createVNode(_component_Button, {
                              class: "btn-cancel",
                              text: _ctx.$t('button.cancel'),
                              dense: true,
                              onHandleClicked: _ctx.handleCancel
                            }, null, 8, ["text", "onHandleClicked"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 512), [
                      [_vShow, _ctx.selectedAbo > 0 && _ctx.billingInfoConfirmed]
                    ])
                  ], 512),
                  false
                    ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                        _createElementVNode("div", _hoisted_81, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            id: "code",
                            name: "code",
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.iHaveACode) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.iHaveACode]
                          ]),
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('form.wantToRedeemCode')), 1)
                        ]),
                        (_ctx.iHaveACode)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                              _createElementVNode("label", _hoisted_83, _toDisplayString(_ctx.$t('form.enterCode')), 1),
                              _withDirectives(_createElementVNode("input", {
                                name: "code",
                                type: "text",
                                placeholder: _ctx.$t('form.enterCode'),
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.code) = $event)),
                                autocomplete: "off"
                              }, null, 8, _hoisted_84), [
                                [_vModelText, _ctx.code]
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("div", _hoisted_85, [
                    _createElementVNode("div", _hoisted_86, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "code",
                        name: "code",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.wantToJoinCompany) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.wantToJoinCompany]
                      ]),
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('form.wantToJoinCompany')), 1)
                    ]),
                    (_ctx.wantToJoinCompany)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                          _createElementVNode("label", _hoisted_88, _toDisplayString(_ctx.$t('form.enterCompanyCode')), 1),
                          _withDirectives(_createElementVNode("input", {
                            name: "code",
                            type: "text",
                            placeholder: _ctx.$t('form.enterCompanyCode'),
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.companyCode) = $event)),
                            autocomplete: "off"
                          }, null, 8, _hoisted_89), [
                            [_vModelText, _ctx.companyCode]
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 512), [
                    [_vShow, _ctx.selectedAbo == 0 && !_ctx.user.abo?.abodata?.companyName]
                  ]),
                  (_ctx.wantToJoinCompany)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        disabled: !_ctx.companyCode,
                        text: _ctx.$t('button.redeemCode'),
                        waitForResponse: _ctx.waitForResponse,
                        onHandleClicked: _ctx.handleJoinCompany
                      }, null, 8, ["disabled", "text", "waitForResponse", "onHandleClicked"]))
                    : _createCommentVNode("", true),
                  (_ctx.iHaveACode)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 2,
                        text: _ctx.$t('button.redeemCode'),
                        waitForResponse: _ctx.waitForResponse,
                        onHandleClicked: _ctx.handleRedeemCode
                      }, null, 8, ["text", "waitForResponse", "onHandleClicked"]))
                    : _createCommentVNode("", true),
                  _withDirectives(_createVNode(_component_Button, {
                    class: "btn-cancel",
                    text: _ctx.$t('button.cancel'),
                    dense: true,
                    waitForResponse: _ctx.waitForResponse,
                    onHandleClicked: _ctx.handleStepBack
                  }, null, 8, ["text", "waitForResponse", "onHandleClicked"]), [
                    [_vShow, _ctx.selectedAbo <= 0]
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_90, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('abo.changed')) + ".", 1),
                  (_ctx.aboChangingStates.aboChanged)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_91, _toDisplayString((_ctx.$t('abo.changedPlanedText', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate), abo: _ctx.getAboDetails()}))), 1))
                    : _createCommentVNode("", true),
                  (_ctx.aboChangingStates.aboCanceled)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_92, _toDisplayString((_ctx.$t('abo.canceledText', {expirationDate: _ctx.formatDate(_ctx.user.abo.expirationDate)}))), 1))
                    : _createCommentVNode("", true),
                  (_ctx.aboChangingStates.aboRedoCanceled)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_93, _toDisplayString((_ctx.$t('abo.redoCanceledText'))), 1))
                    : _createCommentVNode("", true),
                  (_ctx.aboChangingStates.aboRedoChanged)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_94, _toDisplayString((_ctx.$t('abo.redoChangedText'))), 1))
                    : _createCommentVNode("", true),
                  (_ctx.aboChangingStates.willJoinCompany)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_95, _toDisplayString((_ctx.$t('abo.willJoinCompany'))), 1))
                    : _createCommentVNode("", true),
                  (_ctx.aboChangingStates.joinedCompany)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_96, _toDisplayString((_ctx.$t('abo.joinedCompany'))), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Button, {
                    text: _ctx.$t('button.backToAccount'),
                    onHandleClicked: _ctx.handleStepBack
                  }, null, 8, ["text", "onHandleClicked"])
                ]))
          ]))
    ], 2),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error
    }, null, 8, ["show", "text", "error"])
  ]))
}