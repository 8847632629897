
import  {defineComponent, nextTick } from 'vue';
import { AboController, UserController, PaymentController } from '@/controller';
import { Dialog, /* FixedHeaderTop, */ PageHeader, Snackbar } from '@/components/';
import { Button, Select, PasswordInput  } from '@/ui';
import store from '@/store/index';
//import SmoothScrollTo from "@/utils/ScrollTo";

export default defineComponent({
    name: "ManageAbo",
    components: { Snackbar, /* FixedHeaderTop, */ Button, Dialog, PageHeader, Select, PasswordInput },
    data(){
        return{
            store,
            abos: [] as Array<any>,
            payments: [] as Array<any>,
            selectedAbo: 0,
            aboChanged: false,
            user: {} as any, 
            iHaveACode: false,
            wantToJoinCompany: false,
            code: "",
            companyCode: "",
            waitForResponse: false,
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            icons: {
                logo: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
                arrow: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" enable-background="new 0 0 500 500" xml:space="preserve"><g><g><line fill="none" stroke="#163D64" stroke-width="25" stroke-miterlimit="184.1689" x1="0" y1="250" x2="500" y2="250"/></g><g><path fill="none" stroke="#163D64" stroke-width="25" stroke-miterlimit="184.1689" d="M175,429.6c0-96.6-78.3-175-175-175"/><path fill="none" stroke="#163D64" stroke-width="25" stroke-miterlimit="184.1689" d="M0,245.4c96.6,0,175-78.3,175-175"/></g></g></svg>',
                loader: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform="translate(1 1)"><circle class="st0" cx="18" cy="18" r="18"/><path class="st1" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>',
            },
            paymentBrands: '',
            paymentPrepared: false,
            paymentShopperResultUrl: '',
            handleShowConfirmChangeToFreeAbo: false,
            showConfirmCancelDialog: false,
            //showConfirmAutoPayment: false,
            currentUserAbo: {} as any,
            aboChangingStates: {
                aboCanceled: false,
                aboRedoCanceled: false,
                aboChanged: false,
                aboRedoChanged: false,
                joinedCompany: false,
                willJoinCompany: false
            },
            showLoader: false,
            formBilling: {
                street: '' as string,
                housenumber: '' as string,
                zip: '' as string,
                city: '' as string,
                country: 0 as number,
                taxIdentificationNumber: '' as String
            },
            validateFormBilling: false,
            showBillingCreated: false,
            countries: [] as Array<any>,
            isCompany: false,
            billingInfoConfirmed: false,
            formPassword: {
                password: '' as string
            },
            passwordNeeded: false,
            validateFormPassword: false,
            serverInvalidPassword: false
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.fetchData();
    },
    methods: {
        formatDate(ts:any){            
            if(ts){
                const date = new Date(ts*1000) ;
                return date.toLocaleDateString()
            }
            return '--'
        },
        formatPrice(price:any){
            price = price.toFixed(2)
            let string = price.toString()
            return string.replace(".", ",")
        },
        getAboDetails(){
            let aboDetails : string = "--"
            this.abos.forEach((abo:any) => {
                if(abo.uid == this.selectedAbo && abo.uid != 1){
                    aboDetails = abo.title + ' (€' + this.formatPrice(abo.price)+')';
                } else if(abo.uid == this.selectedAbo) {
                    aboDetails = abo.title;
                }
            });
            return aboDetails
        },
        getAboPrice() {
            const abo : any= this.abos.find(abo => abo.uid == this.selectedAbo);
            if (abo) return abo.price
            else return 0
        },      
        async handleSelectAbo(abo:any){
            this.selectedAbo = abo.uid;
            if(abo.price == 0){
                this.paymentPrepared = false
            } else {
                if (!this.countries.length) {
                    this.countries = await UserController.getCountries()
                }
            }
            nextTick(() => {
                (this.$refs.domAboPayment  as any).scrollIntoView({ behavior: "smooth" }) as any
            })
            //(this.$refs.domAboPayment  as any).scrollIntoView({ behavior: "smooth" }) as any
            //SmoothScrollTo.scroll(this.$refs.domAboPayment, 110);
        },
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error
            setTimeout(() => {
                this.snackbar.show = false
           }, 5000)
        },
        handleCancel(){
            this.selectedAbo = 0
            this.paymentPrepared = false
            this.serverInvalidPassword = false
            this.billingInfoConfirmed = false
        },
        handleGoBack(){
            this.$router.push('/')
        },
        handleStepBack(){
            this.$router.push('/account')
        },        
        handleChangePasswordValue(value: string) {
            this.formPassword.password = value
            this.serverInvalidPassword = false
        },
        async fetchData(){
            this.user = await UserController.user
            if (this.user.billing) {
                this.formBilling.street = this.user.billing.street
                this.formBilling.housenumber = this.user.billing.housenumber
                this.formBilling.zip = this.user.billing.zip
                this.formBilling.city = this.user.billing.city
                this.formBilling.country = this.user.billing.countryId
                this.formBilling.taxIdentificationNumber = this.user.billing.taxIdentificationNumber
                if (this.formBilling.taxIdentificationNumber) this.isCompany = true
            }
            this.getAbos();
            this.getPayments();
        },
        async getAbos(){
            let res : any = await AboController.getAbos()
            if(res.data.success){
                this.abos = AboController.abos;
                if (this.$route.params && this.$route.params['abocode']) {
                    const presetAbo = this.abos.find(item => item.abocode == this.$route.params['abocode']);
                    this.selectedAbo = presetAbo.uid;
                }
                //this.currentUserAbo = this.abos.find(item => item.uid == this.user.abo.uid)
                this.currentUserAbo = this.user.abo.abodata;
                /* if (!this.currentUserAbo) {
                    console.log('its a hidden abo?', this.user.abo.uid);
                    this.currentUserAbo = await AboController.getHiddenAbo(this.user.abo.uid)
                } */
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
        async getPayments(){
            await PaymentController.getPayments();
            this.payments = PaymentController.payments;
        },             
        handleConfirmManageAbo(){
            this.handleShowConfirmChangeToFreeAbo = true
        },
        handleCloseDialog(){
            this.handleShowConfirmChangeToFreeAbo = false
        },
        async manageAbo(){
            this.handleCloseDialog()
            this.waitForResponse = true
            let res : any = await AboController.manageAbo(this.selectedAbo)
            if (res.data.success) {
                this.abos = AboController.abos;
                this.aboChanged= true
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.waitForResponse = false
        },
        handleChangeAbo() {
            this.changeAbo()
        },
        async changeAbo(){
            this.waitForResponse = true
            this.showLoader = true
            let res : any = await AboController.changeAbo(this.selectedAbo, this.formPassword.password)
            if (res.data.success) {
                this.abos = AboController.abos
                //this.user = await UserController.user
                //this.currentUserAbo = this.abos.find(item => item.uid == this.user.abo.uid)
                this.aboChangingStates.aboChanged = true
                this.aboChanged= true
            } else {
                this.showBillingCreated = false
                this.billingInfoConfirmed = false
                res.data.errors.forEach((er: any) => {
                    if (er.message == 'error.changesFailedInvalidPassword') this.serverInvalidPassword = true
                })
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.showLoader = false
            this.waitForResponse = false
        },
        /* handleShowConfirmAutoPayment(){
            this.handlePreparePayment()

            //this.showConfirmAutoPayment= true
        },
        async handleConfirmAutoPayment(){
            this.showConfirmAutoPayment= false
            this.handlePreparePayment()
        }, */
        async handlePreparePayment() {
            this.waitForResponse = true
            this.showLoader = true
            let res : any = await PaymentController.paymentData();
            console.log("Home:paymentBrands:res", res);
            if (res.data.success) {
                this.paymentBrands = PaymentController.payment.brands;
                this.paymentShopperResultUrl = PaymentController.payment.shopperResultUrl               
            }
            res = await PaymentController.preparePayment({ 'amount': this.getAboPrice(), 'abo': this.selectedAbo });
            //console.log("Home:preparePayment:res", res);
            this.paymentPrepared = PaymentController.payment.prepared
            this.showLoader = false
            this.waitForResponse = false

            nextTick(() => {
                (this.$refs.domPaymentPrepared  as any).scrollIntoView({ behavior: "smooth" }) as any
            })
        },
        async handleRedeemCode(){
            console.log('TODO: Redeem Code')
        },
        async handleJoinCompany(){
            this.waitForResponse = true
            this.showLoader = true
            let res : any = await AboController.joinCompany(this.companyCode)
            if (res.data.success) {
                this.abos = AboController.abos
                //this.user = await UserController.user
                //this.currentUserAbo = this.abos.find(item => item.uid == this.user.abo.uid)
                if (res.data?.data?.aboNew) {
                    this.aboChangingStates.joinedCompany = true
                } else {
                    this.aboChangingStates.willJoinCompany = true
                }
                this.aboChanged = true
            } else {
                this.showBillingCreated = false
                this.billingInfoConfirmed = false
                res.data.errors.forEach((er: any) => {
                    if (er.message == 'error.changesFailedInvalidPassword') this.serverInvalidPassword = true
                })
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.showLoader = false
            this.waitForResponse = false
        },        
        handleCancelAbo() {
            this.showConfirmCancelDialog = true
        },
        async cancelAbo() {
            this.showConfirmCancelDialog = false
            this.waitForResponse = true
            this.showLoader = true
            let res : any = await AboController.cancelAbo()
            if (res.data.success) {
                this.abos = AboController.abos;
                //this.user = await UserController.user
                //this.currentUserAbo = this.abos.find(item => item.uid == this.user.abo.uid)
                this.aboChangingStates.aboCanceled = true
                this.aboChanged= true
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.showLoader = false
            this.waitForResponse = false            
        },
        async handleRedoCancelAbo() {
            this.waitForResponse = true
            this.showLoader = true
            let res : any = await AboController.redoCancelAbo()
            if (res.data.success) {
                this.abos = AboController.abos;
                //this.user = await UserController.user
                //this.currentUserAbo = this.abos.find(item => item.uid == this.user.abo.uid)
                this.aboChangingStates.aboRedoCanceled = true
                this.aboChanged= true
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.showLoader = false
            this.waitForResponse = false            
        },
        async handleRedoChangeAbo() {
            this.waitForResponse = true
            this.showLoader = true
            let res : any = await AboController.redoChangeAbo()
            if (res.data.success) {
                this.abos = AboController.abos;
                //this.user = await UserController.user
                //this.currentUserAbo = this.abos.find(item => item.uid == this.user.abo.uid)
                this.aboChangingStates.aboRedoChanged = true
                this.aboChanged= true
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.showLoader = false
            this.waitForResponse = false            
        },
        getExistingPaymentData() {
            const lastPayment : any = this.getLastPayment()
            if (lastPayment) {
                if (lastPayment && lastPayment.payment && lastPayment.payment.status == 3) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        getLastPayment(){
            if(this.payments && this.payments.length >0){
                    let lastPayment :any = this.payments[0];
                    this.payments.forEach((payment:any) =>{
                        if(payment.payment.paymentTimestamp > lastPayment.payment.paymentTimestamp){
                            lastPayment =payment
                        }
                    })
                    return lastPayment;
            } else {
                return false
            }
        },
        getErrorClass(fieldType: any, input: any) {
            if (this.validateFormBilling) {
                if (input == "") {
                    return "required"
                }
            }
            if (this.validateFormPassword) {
                if (input == "") {
                    return "required"
                }
                if (this.serverInvalidPassword) return "invalid"
            }
        },        
        onValidateForm() {
            //check other fields
                //                zip: '' as string,  city: '' as string, country: 0 as number
            if (this.formBilling.street.length && this.formBilling.housenumber && this.formBilling.zip.length >= 3 && this.formBilling.city.length && this.formBilling.country != 0 && (!this.validateFormPassword || (this.validateFormPassword && this.formPassword.password))) {
                return true
            }
            /* if (this.validateFormPassword && this.formPassword.password) {
                return true
            } else if (!this.validateFormPassword) {
                return true
            } */
            return false;
        },
        onChangeCountry(ev: any, value: any) {
            this.formBilling.country = parseInt(value)
        },
        onIsCompanyChange() {
            if (!this.isCompany) this.formBilling.taxIdentificationNumber = ''
        },
        handleSubmitBillingForm() {
            this.validateFormBilling = true
            if (this.currentUserAbo.price != 0  && this.getExistingPaymentData()) this.validateFormPassword = true
            if (this.onValidateForm()) {
                this.handleUpdateBilling();
            }
        },
        handleUpdateBilling() {
            this.updateBilling()
            this.validateFormBilling = false;
        },
        async updateBilling(){
            this.waitForResponse = true;
            let res = await UserController.updateBilling(this.formBilling);
         
            if(res.data.success){
                this.showBillingCreated = true
                this.billingInfoConfirmed = true
                if (this.currentUserAbo.price == 0 || !this.getExistingPaymentData()) {
                    this.handlePreparePayment()
                } else if (this.getExistingPaymentData()) {
                    this.changeAbo()
                }
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.waitForResponse = false;
        },     
        /* async createApplePurchaseToken(token: string) {
            const res = await ApplePurchaseController.createToken(token)
            console.log('createApplePurchaseToken response ', res)
        }   */ 
    }
});
